.receiptWrap{
    background-image: url("../../Assets/images/uploadreceiptbg.png");
    padding: 75px 0px;
    @media screen and (max-width: 767px){
        padding: 40px 0px;
    }
    .title h1{
      max-width: 100% !important;
      margin-bottom: 70px;
      background:linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.99)), radial-gradient(139.35% 19.44% at 38.87% 30.8%, rgba(0, 0, 0, 0) 38.77%, rgba(0, 0, 0, 0.8) 56.42%, rgba(255, 255, 255, 0.2) 61.61%), radial-gradient(101.95% 4.59% at 54.87% 58.8%, rgba(0, 0, 0, 0) 38.77%, rgba(0, 0, 0, 0.5) 56.42%, rgba(255, 255, 255, 0.2) 61.61%), radial-gradient(110% 22% at 45% 79%, rgba(0, 0, 0, 0) 38.77%, rgba(0, 0, 0, 0.4) 56.42%, rgba(255, 255, 255, 0.99) 61.61%), radial-gradient(98% 20% at 45% 77%, rgba(0, 0, 0, 0) 38.77%, rgba(0, 0, 0, 0.4) 56.42%, rgba(255, 255, 255, 0.2) 61.61%), radial-gradient(79.42% 17.6% at 50.87% 110%, rgba(0, 0, 0, 0) 38.77%, rgba(0, 0, 0, 0.3) 56.42%, rgba(255, 255, 255, 0.9) 61.61%)!important;
      -webkit-background-clip: text !important;
      -webkit-text-fill-color: transparent;
      @media screen and (max-width:640px){
           margin-bottom: 20px;
           font-size: 35px!important
      }
      @media screen and (max-width:360px){
        margin-bottom: 20px;
        font-size: 33px!important;
    }
    }
    .title.uploadRecipt h1{
        max-width:520px!important;
    }
    .title.notsupported h1{
        max-width:630px!important;
    }
    p{
        color:#fff;
        font-size: 22px;
        line-height: 37px;
        font-weight: 500;
        max-width: 600px;
        @media screen and (max-width: 767px){
            font-size: 16px;
            line-height: 24px;
        }
    }
    .ulpareceiptImg{
        @media screen and (max-width: 767px){
            background-image:url("../../Assets/images/STARBURST 1.png");
            width:100%;
            height:auto;
            background-repeat: no-repeat;
            background-size: contain;
        }
        img{
            @media screen and (max-width: 767px){
                width: 50%;
            }
        }
    }
    .helpfultips{
        padding:24px 42px;
        border: 1px solid #fff;
        border-radius: 10px;
        margin:50px 0px;
        @media only screen and (max-width: 767px){
            padding:18px 20px;
        }
        p{
            font-weight:700;
        }
        ul li{
            color:#fff;
            font-size: 22px;
            line-height: 37px;
            font-weight: 500;
            @media screen and (max-width: 767px){
                font-size: 14px;
                line-height: 24px;
            }
        }
    }
    .uploadbtn{
        position: relative;
    }
    .fileInput{
        width: 251px;
        opacity: 0;
        position: absolute;
        cursor: pointer;
        height: 58px;
        left: 0px;
        top: 0px;
        background-color: red;
        &::-webkit-file-upload-button {
            visibility: hidden!important;
          }
    }
    .btnActionWrap{
        @media screen and (max-width:767px){
            text-align: center;
        }
        .privacyLink{
            @media screen and (max-width:767px){
                text-align: center;
            }
        }
    }
   &.notverified{
        .helpfultips{
            margin:34px 0px 50px!important;
            @media screen and (max-width: 767px){
                margin:50px 0px;
            }
        }
    }
  }

  .selectGamebtn{
    @media screen and (max-width: 767px){
        display:block!important;
        width:150px;
        margin:0 auto;
    }
  }