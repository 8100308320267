
.videosection{
    &.cesSection{
      background-image: url("../../Assets/images/levelupbg.png");
    //   height:953px;
      margin-top: -65px;
      background-position: center top;
      @media screen and (min-width: 1400px){
        // height:1200px;
      }
      @media screen and (max-width: 640px){
        background: url("../../Assets/images/levelupbg-mobileu.png") center top;
        // height: 953px;
        margin-top: -73px !important;
        background-position: center center;
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
      @media screen and (max-width: 413px){
        // height: 860px;
        // margin-top: -70px !important;
      }
      .container{
        height: 100%;
        padding:120px 16px 80px;
        @media screen and (max-width: 640px){
          padding:120px 30px 50px;
        }
        .row{
        //   height: 100%;
        }
      }
      .title{
        color:#fff;
        h2{
          text-transform: uppercase;
          font-size: 56px;
          font-weight: 800;
          background: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.9019607843)), radial-gradient(95% 22% at 26.36% -13%, rgba(0, 0, 0, 0) 38.77%, rgba(0, 0, 0, 0.4) 56.42%, rgba(255, 255, 255, 0.2) 61.61%), radial-gradient(140% 26% at 26.36% 40%, rgba(0, 0, 0, 0) 38.77%, rgba(0, 0, 0, 0.5019607843) 56.42%, rgba(255, 255, 255, 0.2) 61.61%), radial-gradient(198.86% 24% at -26% 91%, rgba(167, 55, 55, 0) 38.77%, rgba(0, 0, 0, 0.4) 56.42%, rgba(255, 255, 255, 0.2) 61.61%), radial-gradient(204% 15% at 26.36% 110%, rgba(0, 0, 0, 0) 38.77%, rgba(0, 0, 0, 0.3019607843) 56.42%, rgba(255, 255, 255, 0.8) 61.61%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          @media screen and (max-width: 640px){
            background:linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.9)),
            radial-gradient(95% 22% at 26.36% -13%, rgba(0, 0, 0, 0) 38.77%, rgba(0, 0, 0, 0.6) 56.42%, rgba(255, 255, 255, 0.2) 61.61%),
            radial-gradient(140% 26% at 26.36% 40%, rgba(0, 0, 0, 0) 38.77%, rgba(0, 0, 0, 0.5) 56.42%, rgba(255, 255, 255, 0.2) 61.61%),
            radial-gradient(204.86% 24% at 28% 87%, rgba(167, 55, 55, 0) 38.77%, rgba(0, 0, 0, 0.4) 56.42%, rgba(255, 255, 255, 0.2) 61.61%),
            radial-gradient(204% 15% at 26.36% 110%, rgba(0, 0, 0, 0) 38.77%, rgba(0, 0, 0, 0.3) 56.42%, rgba(255, 255, 255, 0.8) 61.61%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            font-size: 34px;
            line-height: 40px;
            max-width: 200px;
          }
        }
        p{
          font-size: 18px;
          font-weight: 500;
          color:#FFF;
          padding-top: 10px;
          @media screen and (max-width:640px){
            font-size: 12px;
            padding-bottom: 20px;
          }
        }
      }
      .ceslogo{
        padding-bottom: 25px;
        max-width: 300px;
        @media screen and (max-width: 991px){
         padding:0px 0px 30px;
        }
        @media screen and (max-width: 560px){
          padding: 0 0px 30px;

        }
      }
    }
  }
  .play-levelup{
    padding-top: 50px;
    .play-levelup-text{
        color: #fff;
        max-width: 315px;
        padding-bottom: 25px;
        p{
            font-size: 19px;
            padding: 20px 0px 10px;
            margin: 0;
        }
        text{
            font-size: 16px;
        }
    }
    .play-levelup-img{
        img{
            object-fit: cover;
            width: auto;
        }
    }
    @media screen and (max-width: 991px){
        flex-direction: column-reverse;
        display: flex;
        padding-top: 10px;
        padding-bottom: 10px;
        .play-levelup-img{
            img{
                object-fit: cover;
                width: 100%;
            }
        }
    }
  }
  .levelup-btn{
    padding-top: 0px;
    @media screen and (max-width: 991px){
        padding-top: 40px;
    }
  }
  .privacyLink{
    &.tcbtn{
        margin-bottom: 0px;
        @media screen and (max-width: 991px){
            margin-bottom: 25px;
        }
    }
  }
  .mobilview-btn{
    @media screen and (max-width: 991px){
        display: flex;
        flex-direction: column-reverse;
        padding-bottom: 20px;
    }
  }