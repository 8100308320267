.section-1 {
    h1{
        max-width:566px!important;
        @media screen and (max-width:640){
            max-width: 223px !important;
            padding-top: 0px !important;
        }
    }
    .title {
        p.players{font-size: 18px;}
      }

}
.a.learn-more-btn{
    margin-top:29px;

}
.Videobanner-desktop.section-1 {
    h1{
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.8)),
  radial-gradient(116.35% 15.44% at 26.87% 19.8%, rgba(0, 0, 0, 0) 38.77%, rgba(0, 0, 0, 0.8) 56.42%, rgba(255, 255, 255, 0.2) 61.61%),
  radial-gradient(91.95% 13.59% at 26.87% 44.8%, rgba(0, 0, 0, 0) 38.77%, rgba(0, 0, 0, 0.5) 56.42%, rgba(255, 255, 255, 0.2) 61.61%),
  radial-gradient(79% 15% at 29% 70%, rgba(0, 0, 0, 0) 38.77%, rgba(0, 0, 0, 0.4) 56.42%, rgba(255, 255, 255, 0.2) 61.61%),
  radial-gradient(94% 14% at 28% 95%, rgba(0, 0, 0, 0) 38.77%, rgba(0, 0, 0, 0.4) 56.42%, rgba(255, 255, 255, 0.2) 61.61%),
  radial-gradient(73.42% 15.6% at 26.87% 112%, rgba(0, 0, 0, 0) 38.77%, rgba(0, 0, 0, 0.3) 56.42%, rgba(255, 255, 255, 0.9) 61.61%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: #fff;
  filter: drop-shadow(3px 6px #41364A);
    }
}