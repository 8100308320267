
.loader {
    --d: 92px;
    width: 40px !important;
    height: 40px;
    border-radius: 50%;
    color: #ffffff;
    box-shadow: calc(1* var(--d)) calc(0* var(--d)) 0 0, calc(0.707* var(--d)) calc(0.707* var(--d)) 0 1px, calc(0* var(--d)) calc(1* var(--d)) 0 2px, calc(-0.707* var(--d)) calc(0.707* var(--d)) 0 3px, calc(-1* var(--d)) calc(0* var(--d)) 0 4px, calc(-0.707* var(--d)) calc(-0.707* var(--d)) 0 5px, calc(0* var(--d)) calc(-1* var(--d)) 0 6px;
    animation: l27 1s infinite steps(8);
  }
  @keyframes l27 {
    100% {transform: rotate(1turn)}
  }
  .loaderwrap{
    width:100%;
    height:400px;
    display:flex;
    justify-content: center;
    align-items: center;
  }