.TermsandConditionFooter{
    background-color: #FF42FF;
    height: 48px;
    display:flex;
    justify-content: center;
    align-items: center;
    .tandcLink{
        text-decoration: underline;
        font-family: Work Sans;
        font-size: 22px;
        font-weight: 500;
        line-height: 37.87px;
        text-align: center;
        color:#fff;
        @media screen and (max-width:640px){
            font-size: 16px;
            line-height: 20px;
        }
        

    }
}