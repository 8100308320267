
.selectGamePageWrap{
    background-image: url('../../Assets/images/selectgamebg.png');
    background-size:cover;
    width:100%;
    background-repeat: no-repeat;
    .row{
        margin-left:-30px!important;
        margin-right:-30px!important;
        @media screen and (max-width: 640px) {
            margin-left:0px!important;
            margin-right:0px!important;
        }
    }
    .col-12{
        padding-left:30px;
        padding-right:30px;
        @media screen and (max-width: 640px) {
            padding-left:20px;
            padding-right:20px;
        }
    }
    .title h1{
        font-size: 70px;
        line-height: 76px;
        font-weight: 800;
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.99)), radial-gradient(148.35% 18.44% at 48.87% 36.8%, rgba(0, 1, 0, 0) 38.77%, rgba(0, 0, 0, 0.8) 56.42%, rgba(255, 255, 255, 0.2) 61.61%), radial-gradient(111.95% 22.59% at 54.87% 58.8%, rgba(0, 0, 0, 0) 38.77%, rgba(0, 0, 0, 0.5) 56.42%, rgba(255, 255, 255, 0.2) 61.61%), radial-gradient(154% 20% at 67% 94%, rgba(0, 0, 0, 0) 38.77%, rgba(0, 0, 0, 0.4) 56.42%, rgba(255, 255, 255, 0.99) 61.61%), radial-gradient(98% 20% at 45% 77%, rgba(0, 0, 0, 0) 38.77%, rgba(0, 0, 0, 0.4) 56.42%, rgba(255, 255, 255, 0.2) 61.61%), radial-gradient(79.42% 17.6% at 50.87% 110%, rgba(0, 0, 0, 0) 38.77%, rgba(0, 0, 0, 0.3) 56.42%, rgba(255, 255, 255, 0.9) 61.61%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        color: #fff;
        filter: drop-shadow(3px 6px #41364A);
        max-width: 780px;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        @media screen and (max-width: 992px) {
                font-size: 56px;
                line-height: 58px;
        }
        @media screen and (max-width: 640px) {
                font-size: 35px;
                line-height: 36px;
            }
    }
    .gamesStatusTitle{
        display: flex;
        align-items: center;
        margin-bottom: 70px;
        @media screen and (max-width: 640px) {
            margin-bottom: 15px;

        }
        span{
            font-size: 36px;
            font-weight: 800;
            line-height: 68.4px;
            color:#fff;
            text-shadow: 2.7px 5.4px 0px #41364A;
            @media screen and (max-width: 640px) {
                font-size:28px;

            }
        }

        img{
            margin-right:22px;
            @media screen and (max-width: 640px) {
                margin-right: 12px;
                height: 45px;
            }
        }
    }
    .gameDetail{
        margin-bottom:50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: start;
        width: 100%;
        text-align: left;
        &.unlocked{
            cursor: pointer;
        }
        a{
            text-decoration: none;
        }
        .gameType{
            font-size: 24px;
            font-weight: 700;
            color:#fff;
            height: 65px;
            width: 100%;
            display: flex;
            // flex-direction: column;
            align-items: center;
            justify-content: space-between;
            margin: 0;
            padding-bottom: 20px;
            @media screen and (max-width: 640px) {
                font-size: 16px;
                line-height: 22px;
                height: auto;
                margin-bottom: 0px;
                padding-bottom: 15px;
            }
            span{
                display: block;
            }
            p{
                font-size: 20px;
                margin: 0;
                border: 2px solid #fff;
                padding: 8px 20px;
                border-radius: 10px;
                @media screen and (max-width: 640px) {
                    font-size: 16px;
                    padding: 5px 10px;
                }
            }
        }
        .gameCardWrap{
            width:100%;
            height: auto;
            border-radius: 10px;
            position: relative;
            .gameCard{
                position: relative;
                &.lock{
                    &:after{
                        position: absolute;
                        content: "";
                        width:100%;
                        height: 100%;
                        background-color: rgba(0,0,0,0.5);
                        z-index: 1;
                        left:0px;
                        border-radius: 10px;
                    }
                }
                .gameThumbnail{
                    border-radius: 10px;
                }
                .gameTypeHighlighter{
                    position: absolute;
                    top:0px;
                    right:0px;
                    width: 0;
                    height: 0;
                    border-top: 150px solid rgb(255, 255, 255);
                    border-left: 150px solid transparent;
                    border-top-right-radius: 10px;
                    z-index:2;
                    @media screen and (max-width: 640px) {
                        border-top: 120px solid rgb(255, 255, 255);
                        border-left: 120px solid transparent;
                    }
                }
                .gametypeDetails{
                    position: absolute;
                    right: 21px;
                    top: 3px;
                    transform: rotate(45deg);
                    display: flex;
                    flex-flow: column;
                    justify-content: center;
                    align-items: center;
                    z-index: 2;
                    @media screen and (max-width:640px){
                        right: 26px;
                    }
                    p{
                        font-size: 28px;
                        font-weight: 700;
                        line-height: 37.87px;
                        text-align: center;
                        color:#4A32BE;
                        @media screen and (max-width:640px){
                            font-size: 18px;
                        }
                    }
                }
                .gameTypeIconWrap{
                    position: relative;
                    z-index: 10;
                    left:0px;
                    background-color: #4A32BE;
                    height:37px;
                    width:37px;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    @media screen and (max-width:640px){
                        height:27px;
                        width:27px;
                    }
                    img{
                        transform: rotate(-45deg);
                    }
                }
                .lockIconWrap{
                    display: none;
                    img{
                        @media screen and (max-width:640px){
                            height: 50px;
                        }
                    }
                }
                &.lock{
                    &:after{
                        position: absolute;
                        content: "";
                        width:100%;
                        height: 100%;
                        background-color: rgba(0,0,0,0.5);
                        z-index: 1;
                        top:0px;
                        left:0px;
                        border-radius: 10px;
                    }
                    .lockIconWrap{
                        display: block;
                        position: absolute;
                        top:50%;
                        left:50%;
                        transform: translate(-50% ,-50%);
                        z-index: 3;
                    }
                }
            }

        }
    }
}
